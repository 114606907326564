import React, { useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { AgilityImage } from "@agility/gatsby-image-agilitycms"
import { Search } from "@organic-return/foundation-react/src/components/Search/Search"
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../tailwind.config'
import { useState } from "react"
import { ISearchContext } from "@organic-return/foundation-react/src/components/Search/SearchContext"
import { Choose, Text } from "@organic-return/foundation-react/src/components/Search/Filter"


const MarketWatch = () => {
    // graphql query for posts data
    const data = useStaticQuery(graphql`
    query {
      posts: allAgilityPost(
        filter: { properties: { referenceName: { eq: "posts" } } }
        sort: { fields: customFields___date, order: DESC }
      ) {
        nodes {
          customFields {
            title
            date(formatString: "MMMM DD, YYYY")
            image {
              url
              height
              width
            }
            content
            category {
              contentid
            }
          }
          excerpt          
          path
          sitemapNode {
            path
          }
          tags {
            customFields {
              title
            }
          }
          categories {
            customFields {
              title
            }
          }
        }
      }
    }
  `)

    // get posts
    const posts = data.posts.nodes

    let postsCategoriesOptions: any[] = []
    posts && posts.length > 0 && (
        posts.forEach((post: any) => {
            post?.categories?.forEach((category: any) => {
                if (category?.customFields?.title &&
                    !postsCategoriesOptions.some(item => item.value == category.customFields.title)) {
                    postsCategoriesOptions.push({
                        value: category.customFields.title,
                        label: category.customFields.title
                    })
                }
            })
        })
    )
    let postsTagsOptions: any[] = []
    posts && posts.length > 0 && (
        posts.forEach((post: any) => {
            post?.tags?.forEach((tag: any) => {
                if (tag?.customFields?.title &&
                    !postsTagsOptions.some(item => item.value == tag.customFields.title)) {
                    postsTagsOptions.push({
                        value: tag.customFields.title,
                        label: tag.customFields.title
                    })
                }
            })
        })
    )
    const primaryFilters = function (context: ISearchContext) {
        return (
            <>
                <Text
                    context={context}
                    className="hidden"
                    inputClassName="text-sm rounded-none bg-transparent placeholder-composite-text border-composite-text pl-0"
                    label="Blog search"
                    placeholder="Blog search"
                    param="keyword"
                    resolver={(arg: any, text) => {
                        let argl = arg.customFields;
                        let match = text.toLowerCase();
                        return (argl.title && argl.title.toLowerCase().includes(match)) ||
                            (argl.content &&
                                argl.content.toLowerCase().includes(match))
                            ? true
                            : false;
                    }}
                />
                <Choose
                    className="hidden"
                    context={context}
                    placeholder="Category"
                    label="Category"
                    param="category"
                    resolver={() => { return "categories" }}
                    options={postsCategoriesOptions}
                    customFilter={(arg: any, value) => {
                        const vstr = value as string
                        return arg.categories?.some((category: any) => category.customFields.title == vstr) ?? false
                    }}
                    match="one"
                />
                <Choose
                    className="hidden"
                    context={context}
                    placeholder="Tags"
                    label="Tags"
                    param="tag"
                    resolver={() => { return "tags" }}
                    options={postsTagsOptions}
                    customFilter={(arg: any, value) => {
                        const vstr = value as string
                        return arg.tags?.some((tag: any) => tag.customFields.title == vstr) ?? false
                    }}
                    match="one"
                />
            </>
        )
    }

    const pageSize = 12;

    //Enum with screen breakpoint. Will be useful throughout component
    enum EScreenBreakpoints {
        sm = "sm",
        md = "md",
        lg = "lg",
    }
    //Retrieves tailwind's configuration object
    const fullConfig = resolveConfig(tailwindConfig)
    //Retrieves all defined tailwind screens sizes breakpoints
    const { screens } = fullConfig.theme
    //Function that returns the correspondent screen size break point
    const getCurrentScreenBreakpoint = () => {
        const viewportWidth = typeof window !== "undefined" ? window.outerWidth : 0;
        const mdScreenSize = parseInt(screens.md);
        const lgScreenSize = parseInt(screens.lg);

        if (viewportWidth >= mdScreenSize && viewportWidth < lgScreenSize) {
            return EScreenBreakpoints.md;
        }
        else if (viewportWidth >= lgScreenSize) {
            return EScreenBreakpoints.lg;
        }
        else {
            return EScreenBreakpoints.sm;
        }

    }

    /*
    The idea here is to reproduce the masonry layout. So this function will reorder posts in pages by the screen size
     */
    const getSortedPostsByScreenSize = (): any => {

        let currentScreenBreakpoint = getCurrentScreenBreakpoint();

        let columns = 1;
        //Medium devices => 2 columns
        if (currentScreenBreakpoint == EScreenBreakpoints.md) {
            columns = 2;
        }
        else if (currentScreenBreakpoint == EScreenBreakpoints.lg) {
            columns = 3;
        }

        //if it's a small device breakpoint, just return posts from static query
        if (columns === 1)
            return posts;

        let sortedPosts = [];

        //Here we reorder every page posts...
        for (let p = 0; p < posts.length; p = p + pageSize) {
            let start = p;
            let end = p + pageSize;

            let postsPage = posts.slice(start, end);

            let aux = 0;
            //This feeds the columns...
            while (aux < columns) {
                for (let i = aux; i < postsPage.length; i = i + columns) {
                    sortedPosts.push(postsPage[i]);
                }
                aux++;
            }
        }
        return sortedPosts;
    }

    const [postList, setPostList] = useState(getSortedPostsByScreenSize())

    useEffect(() => {
        const handleWindowResize = () => {
            setPostList(getSortedPostsByScreenSize())
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    })

    const [isMounted, setMounted] = useState(false);
    useEffect(() => {
        setMounted(true);
    }, [])
    if (!isMounted) return null

    // if there are no posts, display message on frontend
    if (posts.length <= 0) {
        return (
            <div className="mt-44 px-6 flex flex-col items-center justify-center">
                <h1 className="text-3xl text-center font-bold">No posts available.</h1>
                <div className="my-10">
                    <Link
                        to="/"
                        className="px-4 py-3 my-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary transition duration-300"
                    >
                        Return Home
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <Search
            index={postList}
            pageSize={pageSize}
            pagerBottom={true}
            showCount={true}
            className="posts-listing max-w-screen-xl mx-auto px-4"
            filterClassName="hidden"
            headerClassName="!hidden"
            resultsClassName="sm:block columns-1 sm:columns-2 lg:columns-3 md:gap-10"
            renderItem={(post, index) => {
                //For lg screens, every "pageSize/3" should create another column
                //For md screens, every "pageSize/2" should create another column
                let currentScreenBreakpoint = getCurrentScreenBreakpoint()
                let breakAfterClass = ""
                if ((currentScreenBreakpoint == EScreenBreakpoints.lg && (index + 1) % (pageSize / 3) === 0) ||
                    (currentScreenBreakpoint == EScreenBreakpoints.md && (index + 1) % (pageSize / 2) === 0)) {
                    breakAfterClass = "md:break-after-column"
                }
                return (
                    <div key={index} className={`block w-full md:w-auto break-inside-avoid mb-7 shadow-lg ${breakAfterClass}`}>
                        {post.customFields?.image?.url && <div className="relative">
                            <Link className="block" to={post.path}>
                                <AgilityImage
                                    image={post.customFields?.image}
                                    width={post.customFields.image?.width}
                                    height={post.customFields.image?.height}
                                    layout="fullWidth"
                                />
                            </Link>
                        </div>}
                        <div className="py-2.5 px-4">
                            {post.tags?.[0] && (
                                <div className="uppercase font-normal text-xs">
                                    {post.tags.map((tag: any, index: number, tags: any) => (
                                        <>
                                            {
                                                <Link
                                                    key={index}
                                                    to={encodeURI(`#filters={"tag":"${tag.customFields.title}"}`)}
                                                    children={tag.customFields.title}
                                                />
                                            }
                                            {index < (tags.length - 1) && (
                                                <>{","}&nbsp;</>
                                            )}
                                        </>
                                    ))}
                                </div>
                            )}
                            <Link to={post.path}><h2 className="mt-1 font-bold text-2xl leading-10 hover:underline">
                                {post.customFields.title}
                            </h2></Link>
                            <div className="post-excerpt text-sm mb-7"
                                dangerouslySetInnerHTML={{ __html: post.excerpt }}>
                            </div>
                            <div className="text-xs">
                                {`Posted on ${new Date(post.customFields.date).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`}
                            </div>
                        </div>
                    </div>
                )
            }}
            primaryFilters={primaryFilters}
        />
    )
}

export default MarketWatch
